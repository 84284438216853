import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { KeofittAboutData } from "../model";
const logo = require("../../static/logo/eisner_logo_small.svg") as string;

export const query = graphql`
  query {
    image: file(name: { eq: "hc_andersen" }) {
      id
      childImageSharp {
        fluid(maxWidth: 448) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    text: markdownRemark(
      frontmatter: { title: { eq: "Hans Christian Andersen" } }
    ) {
      id
      frontmatter {
        title
      }
      html
    }
  }
`;

const HCA = ({ data }: { data: KeofittAboutData }) => {
  return (
    <Layout title="About" padding>
      <Container>
        <div className="max-w-md mx-auto" key={data.image.id}>
          <Img fluid={data.image.childImageSharp.fluid} />
        </div>

        <div className="py-8 fairytale">
          <h2 className="uppercase">{data.text.frontmatter.title}</h2>
          <div
            key={data.text.id}
            dangerouslySetInnerHTML={{ __html: data.text.html }}
          />
        </div>

        <div className="flex flex-row items-center justify-between fairytale">
          <p>
            Paintings and Hans Christian Andersen portrait by Jeppe Eisner -{" "}
            <a
              href="http://www.eisner.dk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.eisner.dk
            </a>
          </p>
          <a
            href="http://www.eisner.dk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="logo" src={logo} />
          </a>
        </div>
      </Container>
    </Layout>
  );
};

export default HCA;
